import * as React from "react";
import { translate } from "@telia/b2b-i18n";
import { B2bLayout } from "@telia/b2b-layout/react-cjs";
import { getAllReleaseNotes, getInnerHtml } from "@telia/b2b-release-notes";
import type { BadgeVariantType } from "@teliads/components/dist/types/types/BadgeVariant";
import { TeliaBadge, TeliaCol, TeliaGrid, TeliaP, TeliaRow } from "@teliads/components/react";

import { currentLang } from "../locale";
import styles from "./Root.module.scss";

const Root = () => {
  const releaseNotes = getAllReleaseNotes(currentLang);

  const getVariant = (type: string): BadgeVariantType => {
    switch (type) {
      case "release":
        return "special-offer";
      case "improvement":
        return "information";
      default:
        return "neutral";
    }
  };

  return (
    <B2bLayout
      pageTitle={translate("releaseNotes.pageTitle")}
      heading={translate("releaseNotes.pageHeading")}
    >
      <div data-testid="header-slot" slot="header">
        <TeliaGrid>
          <TeliaP variant="preamble-200">{translate("releaseNotes.preamble")}</TeliaP>
        </TeliaGrid>
      </div>
      <TeliaGrid>
        {releaseNotes.map((releaseNote) => (
          <TeliaRow key={releaseNote.id}>
            <TeliaCol width={12}>
              <article data-testid="article" className={styles.releaseNote}>
                <div>
                  <TeliaBadge data-testid="badge" variant={getVariant(releaseNote.data.type)}>
                    {translate(`releaseNotes.type.${releaseNote.data.type}`)}
                  </TeliaBadge>
                  <span>•</span>
                  <time data-testid="date" dateTime={releaseNote.data.date}>
                    {releaseNote.data.date}
                  </time>
                </div>

                <div
                  data-testid="content"
                  dangerouslySetInnerHTML={{ __html: getInnerHtml(releaseNote.html) }}
                />
              </article>
            </TeliaCol>
          </TeliaRow>
        ))}
      </TeliaGrid>
    </B2bLayout>
  )

};

export default Root;
