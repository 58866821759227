import { addTranslations, currentLanguage, translate } from "@telia/b2b-i18n";

import en from "./en.json";
import sv from "./sv.json";

export { translate };
export const currentLang = currentLanguage();
export const translateSetup = (): void => {
  addTranslations({ sv, en });
};
