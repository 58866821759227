import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import { translateSetup } from "./locale";
import App from "./components/Root";

translateSetup();

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  // Remove or set to createRoot when upgrading to React 18
  renderType: "render",
  rootComponent: () => <App />,
});

export const { bootstrap, mount, unmount } = lifecycles;
